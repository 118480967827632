<template>
  <base-section
    id="carousel-with-word"
    class="my-0 py-0"
  >
    <v-slide-y-transition>
      <v-carousel
        :v-show="show"
        hide-delimiter-background
        show-arrows-on-hover
        height="450"
        cycle
      >
        <v-carousel-item
          v-for="n in 3"
          :key="n"
          :src="require(`@/assets/carousel-${n}.jpg`)"
          reverse-transition="fade-transition"
          transition="fade-transition"
        />
      </v-carousel>
    </v-slide-y-transition>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionCarouselWithWord',
  }
</script>
